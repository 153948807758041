import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import BookNowButton from './BookNowButton'
import { hero, mobileHero } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="95VH"
          w="100%"
          image={`url(${mobileHero}) center/ cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView textCenter column center w="100%" pt="10px" pb="5px">
            <CFView
              ph="7%"
              pv="5px"
              bg="rgb(0,0,0,.7)"
              style={{
                backdropFilter: 'blur(10px)',
                webkitBackdropFilter: 'blur(10px)',
              }}
            >
              <CFView column center>
                <CFText color="white" h1 raleway bold>
                  NOW TAKING ONLINE ORDERS
                </CFText>
              </CFView>
              <CFView mv="10px" pulsate>
                <OrderPickupButton />
              </CFView>
              <CFView mv="10px">
                <BookNowButton />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="95vh"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) top/ cover no-repeat`}
          zIndex={90}
          column
          justifyBetween
          alignCenter
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
        >
          <Header />
          <CFView
            column
            center
            alignCenter
            w="100%"
            pt="8px"
            pb="5px"
            maxWidth="1400px"
            bg="rgb(0,0,0,.1)"
            style={{
              backdropFilter: 'blur(10px)',
              webkitBackdropFilter: 'blur(10px)',
            }}
          >
            <CFView column justifyStart>
              <CFText color="white" h1 raleway bold>
                NOW TAKING ONLINE ORDERS
              </CFText>
            </CFView>
            <CFView row center>
              <CFView mt="10px" ml="20px" pulsate>
                <OrderPickupButton />
              </CFView>
              <CFView mt="10px" ml="20px">
                <BookNowButton />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
