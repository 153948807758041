import React from 'react'
import {
  CFSelect,
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { bookNowButton } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => (
        <CFView>
          <MobileScreen>
            <a
              href={`https://www.opentable.ca/r/yohachi-sushi-japanese-restaurant-reservations-north-vancouver?restref=1373737&lang=en-CA&ot_source=Restaurant%20website`}
              target="_blank"
            >
              <CFView hover>
                <CFImage src={bookNowButton} w="300px" alt="About" />
              </CFView>
            </a>
          </MobileScreen>
          <DefaultScreen>
            <a
              href={`https://www.opentable.ca/r/yohachi-sushi-japanese-restaurant-reservations-north-vancouver?restref=1373737&lang=en-CA&ot_source=Restaurant%20website`}
              target="_blank"
            >
              <CFView hover>
                <CFImage src={bookNowButton} maxWidth="300px" alt="About" />
              </CFView>
            </a>
          </DefaultScreen>
        </CFView>
      )}
    </CFSelect>
  )
}
